body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  bottom: 0%;
  position: fixed;
  background-color: #1a6070;
  width: 100%;
  left: 0%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.footer-text {
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 200;
  text-align: center;
  font-size: 16px;
}

.footer small {
  color: white;
}

.footer-div {
  text-align: center;
}

